<script src="@/api/index.js"></script>
<script src="@/utils/request.js"></script>
<template>
	<div class="container">
		<!-- 固定背景 -->
		<!-- 头部图片 -->
		<div class="top">
			<!-- <div class="time_show">{{datiTimer}}</div> -->
			<img src="../../assets/img/top1.png" alt="">
		</div>
		<!-- 答题题目 -->
		<div class="dati">
			<!-- 单选 -->
			<div class="dati_all">
				<!-- 题目 -->
				<div v-if="list[index].typedata==1">
					<div class="dati_class">单选题</div>
					<div class="title_row">
						<label class="number">{{index+1}}.</label>
						<div class="title">
							{{list[index].title}}
						</div>
					</div>
					<!-- 选项 -->
					<div v-for="(x,y) in list[index].options" :key="x.id">
						<div :class="['option',{'active':x.active}]" @click="option(index,x,y)">
							<div class="option_key">{{x.K}}.</div>
							<div class="option_value">{{x.value}}</div>
						</div>
					</div>
				</div>
				<div v-if="list[index].typedata==2">
					<div class="dati_class">多选题</div>
					<div class="title_row">
						<label class="number">{{index+1}}.</label>
						<div class="title">
							{{list[index].title}}
						</div>
					</div>
					<!-- 选项 -->
					<div v-for="(x,y) in list[index].options" :key="x.id">
						<div :class="['option',{'active':x.active}]" @click="checkbox(index,x,y)">
							<div class="option_key">{{x.K}}.</div>
							<div class="option_value">{{x.value}}</div>
						</div>
					</div>
				</div>
				<div v-if="list[index].typedata==3">
					<div class="dati_class">判断题</div>
					<div class="title_row">
						<label class="number">{{index+1}}.</label>
						<div class="title">
							{{list[index].title}}
						</div>
					</div>
					<!-- 选项 -->
					<div v-for="(x,y) in list[index].options" :key="x.id">
						<div :class="['option',{'active':x.active}]" @click="judge(index,x,y)">
							<!-- <div class="option_key">{{x.K}}.</div> -->
							<div class="option_value">{{x.value}}</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 底部 -->
			<div class="dati_button">
				<div class="dati_btn_box">
					<div :style="index==0?'background: #D9D9D9':''" class="dati_button_btn" @click="prevQue()">上一题</div>
					<div :style="index==list.length-1?'background: #D9D9D9':''" class="dati_button_btn"
						@click="nextQue()">下一题</div>
				</div>
				<div class="dati_button_btn" @click="ifSub()">提交</div>
			</div>
			<div class="now_time">
				<p>一距离答题结束还剩一</p>
				<div class="time_num">
					<div>00</div>:<div>{{min}}</div>:<div>{{second}}</div>
				</div>
			</div>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;margin: 20px auto 0px;display: block;" alt="">
	</div>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex'
	import {
		getStartExam, //正式答题
		getAnswer, //答案
	} from '@/api/index.js'
	import {
		Toast,
		Dialog
	} from 'vant';
	export default {
		name: "daTi",
		data() {
			return {
				seconds: 2400, //答题40分钟计时
				datiTimer: '',
				min: '',//分
				second: '',//秒
				time: null,
				list: [],
				flag: false, //判断选中样式s
				index: 0, //当前题目
			}
		},
		computed: {

		},
		beforeunload() {
			localStorage.setItem('formalAnswer', JSON.stringify(this.list))
		},
		beforeCreate() {

		},
		created() {
			// 时间存入缓存
			if (localStorage.getItem('starTime') == null || localStorage.getItem('starTime') == '') {
				localStorage.setItem('starTime', parseInt(new Date().getTime()/1000)+2400)//这里+剩余的时间
				localStorage.setItem('index', 0)
			}
		},
		mounted() {
			if (localStorage.getItem('formalAnswer') == null) {
				this.getSubject()
			} else {
				this.getCacheSubject()
			}
			if(localStorage.getItem('starTime') != null && localStorage.getItem('starTime') != ''){
				this.seconds = localStorage.getItem('starTime') - parseInt(new Date().getTime()/1000)
				this.index = Number(localStorage.getItem('index'))
				this.Time()
			}
		},
		methods: {
			...mapActions(["changS", "changeTime"]),
			// 答题用时
			answerTime() {
				let m = parseInt(this.seconds / 60 % 60)
				let s = parseInt(this.seconds % 60);
				this.datiTimer = (m < 10 ? '0' + m : m) + ' ' + ':' + ' ' + (s < 10 ? '0' + s : s)
				this.min = m < 10 ? '0' + m : m
				this.second = s < 10 ? '0' + s : s
			},
			// 时间倒计时
			Time() {
				this.time = setInterval(() => {
					if (0>=this.seconds) {
						clearInterval(this.time)
						Dialog.confirm({
								title: '提示',
								message: '时间已到！',
								showCancelButton: false,
							})
							.then(() => {
								this.submit()
							})
					} else {
						this.seconds = this.seconds - 1;
						this.answerTime()
					}
				}, 1000)
			},
			// 单选
			option(index, x, y) {
				this.list.forEach((_item, _index) => {
					if (index == _index) {
						// console.log(_item)
						_item.options.forEach((_x, _y) => {
							if (y == _y) {
								_x.active = true;
								// console.log(_x.key,"选项")
							} else {
								_x.active = false;
							}
						})
					}
				})
				localStorage.setItem('formalAnswer',JSON.stringify(this.list))
				console.log(JSON.stringify(this.list))
			},
			//多选
			checkbox(index, x, y) {
				// console.log(this.list1[index].option[y])
				this.list[index].options[y].active = !this.list[index].options[y].active;
				localStorage.setItem('formalAnswer', JSON.stringify(this.list))
			},
			// 判断
			judge(index, x, y) {
				this.list.forEach((_item, _index) => {
					if (index == _index) {
						// console.log(_item)
						_item.options.forEach((_x, _y) => {
							if (y == _y) {
								// console.log(_x)
								_x.active = true;
							} else {
								_x.active = false;
							}
						})
					}
				})
				localStorage.setItem('formalAnswer', JSON.stringify(this.list))
			},
			//上一题
			prevQue() {
				if (this.index == 0) {
					return false;
				}
				this.index = Number(this.index) - 1
				localStorage.setItem('index', this.index)
			},
			//下一题
			nextQue() {
				if (this.index == this.list.length - 1) {
					return false;
				}
				this.index = Number(this.index) + 1
				localStorage.setItem('index', this.index)
			},
			ifSub() {
				Dialog.confirm({
						title: '提示',
						message: '是否提交答题记录',
					})
					.then(() => {
						this.submit()
					})
			},
			// 提交
			submit() {
				//获取所有答题结果
				try {
					// 单选
					let listArr = []; //定义单选题选中的内容与id
					this.list.forEach((item, index) => {
						// 获取选中的选项
						let listValue = this.list[index].options.filter(e => {
							return e.active
						})
						// console.log(listValue, "获取选中的选项（单选第0个）")
						//没有选中的值提示请选择
						// if (listValue.length == 0 && this.seconds != 0) {
						// 	this.$toast(`请检查第${index+1}道题`)
						// 	throw Error("list")
						// }
						let _answer = []
						listValue.forEach((x, y) => {
							_answer.push(x.key)
						})
						this.list[index].answer = listValue.length == 0 ? [] : _answer;
						listArr.push({
							id: this.list[index].id,
							options: this.list[index].options,
							answer: this.list[index].answer,
							title: this.list[index].title,
							typedata: this.list[index].typedata
						})
					})
					//获取所有答题结果
					let data = listArr;
					throw Error(JSON.stringify(data))
				} catch (e) {
					//TODO handle the exception
					if (e.message === 'list' || e.message === 'list1' || e.message === 'list2') {
						return;
					} else {
						// 清除答题用时计时
						clearInterval(this.time)
						// 获取传给后端的值
						let data = JSON.parse(e.message).map((item, index) => {
							return {
								id: item.id,
								answer: item.answer
							}
						})
						//提交动画
						const toast = Toast.loading({
							forbidClick: true,
							message: '正在提交...',
							duration: 0
						});
						getAnswer({
							data: JSON.stringify(data)
						}).then(res => {
							console.log(res.data.code, "答题")
							if (res.data.code == 1) {
								this.removeList()
								toast.clear();
								this.$router.replace({
									name: 'hosdati',
								})
								// this.$router.replace({
								// 	name: 'cj',
								// 	query: {
								// 		id: res.data.data.id
								// 	}
								// })
							} else {
								toast.clear();
								if(res.data.msg.indexOf('非法访问')!=-1){
									Dialog.confirm({
										title: '提示',
										message: '答题超时无效，请重新答题',
										showCancelButton: false,
									})
									.then(() => {
										this.removeList()
										this.$router.go(-1)
									})
								}else{
									this.$toast(res.data.msg)
								}
							}
						})
						console.log(data, "传给后端的值")
					}
				}
			},
			//获取题目
			getSubject() {
				const toast = Toast.loading({
					forbidClick: true,
					message: '题目加载中...',
					duration: 0
				});
				// let formalAnswer = []
				getStartExam({
					token: localStorage.getItem('token')
				}).then(res => {
					if (res.data.code == 1) {
						let questions = res.data.data.questions;
						// formalAnswer = [...questions];
						this.addFormalAnswer(questions)
						toast.clear()
					} else {
						localStorage.removeItem('starTime');
						toast.clear()
						this.$toast(res.data.msg)
					}
					// console.log(formalAnswer, "题目")
				})

			},
			// 在选项中添加字段
			addFormalAnswer(formalAnswer) {
				formalAnswer.forEach((item, index) => {
					if (item.typedata != 3) {
						item.options = item.options.map((x, y) => {
							return {
								K: x.K,
								key: x.key,
								value: x.value,
								answer: x.answer,
								active: false
							}
						})
					} else {
						let arr = {
							key: 1,
							value: '对',
							active: false
						}
						let arr1 = {
							key: 0,
							value: '错',
							active: false
						}
						item.options.push(arr, arr1)
					}
				})
				// console.log(formalAnswer, "选项option中是否有active字段")
				// 答题存入缓存中
				// console.log(formalAnswer,'1111')
				localStorage.setItem('formalAnswer', JSON.stringify(formalAnswer))
				this.getCacheSubject()
			},
			// 获取vuex中保存的题目为了防止用户刷新重新获取题目
			getCacheSubject() {
				this.list = JSON.parse(localStorage.getItem('formalAnswer'));
				Toast.clear()
			},
			removeList(){
				clearInterval(this.time)
				localStorage.removeItem('formalAnswer');
				localStorage.removeItem('starTime');
				localStorage.removeItem('index');
			},
		},
		destroyed() {
			
		}

	}
</script>
<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding-bottom: 50px;
		background: url(../../assets/img/allbanner.png) no-repeat center 0;
		background-size: cover;

		.top {
			width: 100%;
			height: 260px;
			background-size: 100% 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 50px;
			padding-top: 80px;
			img{
				width: 80%;
			}
			.time_show {
				width: 230px;
				height: 160px;
				background: url(../../assets/img/timeshow.png) no-repeat center;
				background-size: contain;
				margin-bottom: -100px;
				padding: 70px 0px 0px 100px;
				font-size: 50px;
				color: white;
			}

			.top2 {
				width: 180px;
				height: 180px;
				margin-left: 35px;
			}

			.earth {
				width: 300px;
				height: 300px;
			}
		}

		.time {
			width: 90%;
			height: 80px;
			background: #fff;
			border-radius: 20px;
			margin: 0 auto;
			font-size: 30px;
			font-weight: 600;
			line-height: 80px;

			.timeflex {
				display: flex;
				flex-direction: row;
				align-items: center;

				img {
					width: 50px;
					height: 50px;
					margin-right: 15px;
					margin-left: 30px;
				}

				.t {
					color: red;
				}
			}
		}

		.dati {
			width: 85%;
			background: #fff;
			border-radius: 20px;
			margin: -60px auto 0px;
			overflow-y: scroll;
			position: relative;

			.dati_all {
				padding: 30px;

				.dati_class {
					font-size: 18px;
					width: 80px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					background-color: #159B9C;
					border-radius: 10px;
					color: white;
				}

				.title_row {
					width: 95%;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					padding-top: 40px;
					padding-bottom: 40px;
					margin: auto;
					font-size: 24px;
					font-weight: bold;

					.number {
						// width: 55px;
						// height: 55px;
						// background: #CCCCCC;
						// color: #fff;
						// border-radius: 50%;
						// text-align: center;
						// line-height: 55px;
						// font-size: 30px;
					}

					.title {
						width: 550px;
						// font-size: 35px;
						font-weight: 600;
						align-self: center;
					}
				}

				.option {
					display: flex;
					width: 95%;
					// height: 80px;
					border-radius: 5px;
					font-size: 25px;
					align-items: center;
					margin: 0 auto 20px auto;
					background-color: #f2f2f2;
					padding-left: 20px;
					.option_key {
						// margin-left: 50px;
						font-weight: 600;
					}

					.option_value {
						margin: 20px 30px;
					}
				}


				.judge {
					width: 95%;
					height: 80px;
					border: 1px solid #DDDDDD;
					border-radius: 50px;
					font-size: 35px;
					margin: 0 auto 20px auto;
					line-height: 80px;

					.judge_value {
						line-height: 80px;
						margin-left: 30px;
					}
				}
			}

			.dati_button {
				width: 100%;
				margin-bottom: 50px;
				.dati_btn_box {
					display: flex;
					width: 580px;
					margin: 0px auto 30px;
					justify-content: space-between;
					.dati_button_btn:nth-of-type(1){
						background-color: #36CED1;
					}
					.dati_button_btn:nth-of-type(2){
						background-color: #169C9F;
					}
				}

				.dati_button_btn {
					margin: 0 auto;
					width: 250px;
					height: 60px;
					background-color: #FFA028;
					color: #fff;
					font-size: 30px;
					border-radius: 5px;
					text-align: center;
					line-height: 60px;
				}
			}
			.now_time{
				border-top: 1px solid #eff3f6;
				width: 90%;
				margin: auto;
				p{
					font-size: 24px;
					text-align: center;
					letter-spacing: 5px;
				}
				.time_num{
					display: flex;
					justify-content: center;
					div{
						margin: 0px 10px 50px;
						font-size: 60px;
						text-align: center;
						width: 90px;
						height: 90px;
						line-height: 90px;
						border-radius: 10px;
						background-color: #DCE7EB;
						// font-weight: bold;
					}
				}
			}
		}

		.active {
			background-color: #FFE5CA !important;
			// color: #448cfa;
		}
	}
</style>